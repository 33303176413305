<template>
  <div class="kecheng">
    <kind-choose-view :show-school="true" :show-kc="false" :type="15" :schoole="school" :kind="kind" :entrants-type="3"
      :zy-id="zyId" @isOk="isOk" />
    <div class="kecent">
      <div class="kecent_item" v-for="(item, index) in list" :key="index" @click="imgxq(index, item)">
        <div class="item_img">
          <el-image class="img" :src="item.img"></el-image>
          <div class="jie" v-if="item.curForms.length > 0">
            <span v-for="(curForms, curindex) in item.curForms" :key="curindex">
              {{ curindex != 0 ? ` · ` : "" }}{{ curForms }}
            </span>
          </div>
          <div class="jing" v-if="item.productLine == 2">
            <el-image :src="require('@/assets/img/partner/jingpin.png')"></el-image>
          </div>
        </div>
        <div :class="item.productLine == 2 ? 'item_content_jing' : 'item_content'">
          <div class="item_title">{{ item.tiitle }}</div>
          <div class="item_price">
            <div class="popularity">
              <el-image class="img" :src="require('@/assets/img/partner/hot.png')"></el-image>
              <span class="popularity_tip">人气值{{ item.clicks }}</span>
            </div>
            <div class="price">￥{{ item.price }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 暂无课程 -->
    <div v-if="!list || list.length == 0" class="nothing">
      <!-- <img class="nothing-img" src="@/assets/img/study/noLearn.png" /> -->
      <div class="nothing-text">暂无内容哦~</div>
    </div>
    <div style="
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px auto;
      ">
      <el-pagination class="pagination colleges-pagination" :total="total" :current-page="pageNum"
        :page-sizes="[12, 24, 28, 32]" :page-size="pageSize" :pager-count="5"
        layout="total, sizes, prev, pager, next, jumper" @current-change="handleCurrentChange"
        @size-change="handleSizeChange" />
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import KindChooseView from "../../components/kindChooseViewktypevipSeventh.vue";
import {
  getSurfaceList,
  selectNewPageListToIndex,
  selectPageListToIndex,
} from "@/api/home";
import { getDomain } from "@/api/cookies";

export default {
  components: { KindChooseView },

  data() {
    return {
      imgList: {},
      list: {},
      search: {},
      pageSize: 12,
      pageNum: 1,
      total: 0,
      kind: null,
      zyId: null,
      school: null,
      domain: null,
      timer: null,
      switch: false,
      loading: null,
    };
  },
  async beforeDestroy() {
    this.loading.close();
  },
  async created() {
    this.domain = getDomain();
    await getSurfaceList(4, undefined, this.domain.entrantsType).then((res) => {
      if (res.code == 0) {
        if (res.msg.length > 0) {
          this.imgList = res.msg[0];
        } else {
          this.imgList = {};
        }
      }
    });
    if (this.$route.query.kind) {
      this.kind = await Number(this.$route.query.kind);
      this.search.kind = await Number(this.$route.query.kind);
    }
    if (this.$route.query.zId) {
      this.zyId = await Number(this.$route.query.zId);
      this.search.zyId = await Number(this.$route.query.zId);
    }
    if (this.$route.query.school) {
      this.school = await Number(this.$route.query.school);
      this.search.school = await Number(this.$route.query.school);
    }
    await this.getList();
  },
  methods: {
    // 获取知识套餐列表
    getList() {
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.1)",
      });
      selectNewPageListToIndex(
        this.pageSize,
        this.pageNum,
        this.search.school,
        this.search.kind,
        this.search.zyId,
        this.search.type,
        this.search.productLine,
        this.search.kind == 1 ? "专升本" : this.search.stageName,
        this.search.areaId == 1 ? undefined : this.search.areaId
      )
        .then((res) => {
          for (const item of res.rows) {
            if (item.curForms) {
              item.curForms = item.curForms.split(",");
            }
          }
          this.list = res.rows;
          this.total = res.total;
          if (!this.switch) {
            this.switch = true;
          }
          this.loading.close();
        })
        .catch(() => {
          this.loading.close();
        });
    },

    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getList();
    },
    // 筛选
    isOk(val) {
      console.log(val);
      if (this.switch) {
        this.search = val;
        this.pageNum = 1;
        this.getList();
      } else {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.search = val;
          this.pageNum = 1;
          this.getList();
        }, 1000);
      }
    },

    // 点击图片跳转到详情页
    imgxq(index) {
      /* this.$router.push({
        name: "知识套餐课详情",
        query: {
          id: this.list[index].id,
          tenantid: this.search.tenantid,
          type: this.search.type,
          entrantsPrice: this.list[index].entrantsPrice,
          jgPrice: this.list[index].jgPrice,
        },
      }); */
      const routeUrl = this.$router.resolve({
        path: "/typeclassxq",
        query: {
          id: this.list[index].id,
          tenantid: this.search.tenantid,
          type: this.search.type,
          entrantsPrice: this.list[index].entrantsPrice,
          jgPrice: this.list[index].jgPrice,
        },
      });
      window.open(routeUrl.href, "_blank");
    },
  },
};
</script>
<style lang="less" scoped>
.kecheng {
  width: 100%;
  height: 100%;
  background: #f5f7fa;
  overflow: hidden;

  .kecent {
    width: 1200px;
    margin: 20px auto 0;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    .kecent_item {
      width: 280px;
      height: 278px;
      background: #ffffff;
      border-radius: 10px 10px 10px 10px;
      opacity: 1;
      margin-right: 26px;
      margin-bottom: 20px;

      &:nth-child(4n) {
        margin-right: 0px;
      }

      .item_img {
        width: 280px;
        height: 157px;
        border-radius: 6px 6px 0px 0px;
        opacity: 1;
        position: relative;

        .img {
          width: 100%;
          height: 100%;
          border-radius: 6px;
        }

        .jie {
          // height: 18px;
          background: rgba(0, 0, 0, 0.4);
          padding: 3px 10px;
          border-radius: 9px;
          position: absolute;
          left: 16px;
          bottom: 10px;
          font-size: 10px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          // letter-spacing: 2px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .jing {
          width: 66px;
          height: 22px;
          position: absolute;
          top: 0;
          right: 0;
        }
      }

      .item_content,
      .item_content_jing {
        padding: 17px;

        .item_title {
          height: 46px;
          font-size: 16px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #333333;
        }

        .item_price {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 16px;

          .popularity {
            display: flex;
            align-items: center;

            .img {
              width: 12px;
              height: 16px;
            }

            .popularity_tip {
              font-size: 12px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #777a82;
              margin-left: 5px;
            }
          }

          .price {
            font-size: 18px;
            font-family: PingFang SC-Bold, PingFang SC;
            font-weight: bold;
            color: #fb2d25;
          }
        }
      }

      .item_content_jing {
        background: url("../../assets/img//partner/jingpinBg.png") no-repeat;
        background-size: 100%;
      }
    }
  }
}

.isNull {
  width: 1200px;
  margin: 0px auto;
}

.price-icon {
  font-size: 14px;

}
</style>
